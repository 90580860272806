import { Link } from "react-router-dom";
import DefaultLogo from "client/assets/images/transparent-logo-colored.webp"; // Default logo
import ScrolledLogo from "client/assets/images/nontransparent-logo.webp"; // Logo for scrolled state

const YmpLogo = ({
  linkClassName = "menu-logo",
  imageClassName = "img-fluid",
  isScrolled,
  onClick,
}) => {
  return (
    <Link
      to="/"
      className={linkClassName}
      onClick={() => (onClick ? onClick() : {})}
    >
      <img
        src={isScrolled ? ScrolledLogo : DefaultLogo}
        className={imageClassName}
        alt="Logo"
      />
    </Link>
  );
};

export default YmpLogo;
