import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ThankYou.module.css"; // Custom CSS module for styling

const ThankYou = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <div className={styles.thankYouContainer}>
      <div className={styles.messageBox}>
        <h1>{t("thankYou.title", { defaultValue: "Thank You!" })}</h1>
        <p>
          {t("thankYou.message", {
            defaultValue:
              "We appreciate your inquiry. Our team will contact you shortly.",
          })}
        </p>
        <button
          onClick={handleBackToHome}
          className={`btn btn-primary ${styles.backToHomeButton}`}
        >
          {t("thankYou.backToHome", { defaultValue: "Back to Home" })}
        </button>
      </div>
    </div>
  );
};

export default ThankYou;
