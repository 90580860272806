import { APIRequest } from "common";

const getSpecialities = () => {
  const url = `${process.env.REACT_APP_YMP_API}/api/v1/speciality`;

  return APIRequest({
    url: url,
    queryParams: { limit: 100, offset: 0 },
    method: "get",
  });
};

const SpecialityService = {
  getSpecialities,
};

export default SpecialityService;
