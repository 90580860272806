// Example structure of ContactService in client/services/contact.service.js
import { APIRequest } from "common";

const ContactService = {
  submit: (data) => {
    return APIRequest({
      url: `${process.env.REACT_APP_YMP_API}/api/v1/contact`,
      method: "post",
      requestBody: data,
    });
  },
};

export default ContactService;
