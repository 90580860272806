// client/services/reviews.service.js
import { APIRequest } from "common";

// Flattened reviews data
const reviews = {
  en: [
    {
      id: "review1",
      author_name: "Elena Pecherskaya",
      rating: 5,
      relative_time_description: "a week ago",
      text: "Medical Park team Antalya, Thank you for the excellent service. I have done an Executive women package and I was amazed how efficient and professional your work was. Special thanks for Anise, Sophia, and Mete, they supervised and supported me and helped me to communicate with the doctors. I recommend the clinic to every one who wants to check the health and has any health concerns. The clinic was very clean, professional and friendly. I will definitely be back next year. Thank you again!",
      time: new Date().getTime() - 7 * 24 * 60 * 60 * 1000, // 1 week ago
      location_name: "Medical Park Antalya Hastanesi",
      location_type: "Private hospital",
      location_area: "Fener",
      review_link: "https://maps.app.goo.gl/LTHd2RkYHbKw8ic68"
    },
    {
      id: "review2",
      author_name: "Zubayda Tashova",
      rating: 5,
      relative_time_description: "a week ago",
      text: "Dear Medical Park Antalya members, I would like to thank you all for your help and endless care for my mom, recently we brought my mother to Gynecologic Oncology Center of Medical Park Antalya, where she had an operation for deleting cancer from her uterus. Hereby, I would like to thank Prof. Dr. Mehmet Karaca, who did operation for my mom and took care of her, especially his accurate planning for her treatment helped my mom recover soon. Also I would like to thank the nurses of Post-operation center, Esra Cipdem and Hatice Nur Karatas for their endless help and care for my mom, they were super kind and did their job more than required, cheered my mom, supported her after her operation physically and mentally as well.",
      time: new Date().getTime() - 8 * 24 * 60 * 60 * 1000, // 8 days ago
      location_name: "Medical Park Antalya Hastanesi",
      location_type: "Private hospital",
      location_area: "Fener",
      review_link: "https://maps.app.goo.gl/WaXgTKm69CUwgJRt8"
    },
    {
      id: "review3",
      author_name: "Safia Al Toqui",
      rating: 5,
      relative_time_description: "3 months ago",
      text: "We always visit the hospital while we are on holiday. The service is good with professional translator. Keep up the good job",
      time: new Date().getTime() - 90 * 24 * 60 * 60 * 1000, // 3 months ago
      location_name: "Medical Park Antalya Hastanesi",
      location_type: "Private hospital",
      location_area: "Fener",
      review_link: "https://maps.app.goo.gl/MTUgKrpxhP4G5DGW6"
    },
    {
      id: "review4",
      author_name: "Ron Agyeman",
      rating: 5,
      relative_time_description: "a month ago",
      text: "Our experience at VM Medical Park Pendik was truly outstanding! From the moment we arrived, we were treated with exceptional care and professionalism. The doctors, especially the specialist and the endocrinologist, were highly skilled, thorough, and compassionate, ensuring we felt confident and well cared for. The nurses were equally amazing—attentive, kind, and always ready to assist. A special thanks to the translator team, whose dedication made every interaction smooth and stress-free. Their support ensured that we never felt like strangers in a foreign land.",
      time: new Date().getTime() - 30 * 24 * 60 * 60 * 1000, // 1 month ago
      location_name: "VM Medical Park Pendik Hastanesi",
      location_type: "General hospital",
      location_area: "Fevzi Çakmak",
      review_link: "https://maps.app.goo.gl/iKHtaic3LHhridBF9"
    },
    {
      id: "review5",
      author_name: "Lyazzat Duzbayeva",
      rating: 5,
      relative_time_description: "a week ago",
      text: "We underwent surgery to remove a spinal hernia at L5-L4 with neurologist Prof. Dr. Erhan Arslan. The work of the doctor and his team deserves the highest praise - their professionalism at every stage was impressive. The hospital meets the highest standards: attentive staff, comfortable rooms, and excellent food. Special thanks to our assistant, Dilan Aydin, who helped with all the organizational matters. We are truly grateful for the successful treatment!",
      time: new Date().getTime() - 6 * 24 * 60 * 60 * 1000, // 6 days ago
      location_name: "VM Medical Park Pendik Hastanesi",
      location_type: "General hospital",
      location_area: "Fevzi Çakmak",
      review_link: "https://maps.app.goo.gl/5kRW7HXdakXZufjx8"
    },
    {
      id: "review6",
      author_name: "Ha San AkAy",
      rating: 5,
      relative_time_description: "a year ago",
      text: "Excellent place to get all your plastic surgery, hair growth/transplant and many other services. Staff is excellent ready to help you, doctors are skilled and expert in their field.",
      time: new Date().getTime() - 365 * 24 * 60 * 60 * 1000,
      location_name: "Estethica Atasehir",
      location_type: "Plastic surgery clinic",
      location_area: "Küçükbakkalköy",
      review_link: "https://maps.app.goo.gl/idZAjCE4g81NkryRA"
    },
    {
      id: "review7",
      author_name: "David Ilariani",
      rating: 5,
      relative_time_description: "4 months ago",
      text: "We came from Georgia ee and we are very thankful for this clinic, with it's highly professional doctors and nurses, especially I want to mention the translator miss. Sureya, she translated all the information in highly professionally understandable and it's was seen that she is really strongly educated in medicine and during this 5 hour Investigations process she was all the time with us and helped us. Thank you 🙏",
      time: new Date().getTime() - 120 * 24 * 60 * 60 * 1000,
      location_name: "Dünyagöz Hospital - Antalya",
      location_type: "Eye care center",
      location_area: "Zafer",
      review_link: "https://maps.app.goo.gl/qjT2M8Z25y7fnHUd6"
    },
  ],
};

const ReviewService = {
  getReviews: async (language = "en") => {
    // When API is ready, uncomment this block and remove the dummy data
    /*
    return APIRequest({
      url: `${process.env.REACT_APP_YMP_API}/api/v1/reviews`,
      method: "get",
      params: { language }
    });
    */

    // Simulate API delay
    await new Promise((resolve) => setTimeout(resolve, 800));

    // Return language-specific reviews or fall back to English
    return new Promise((resolve) => {
      resolve(reviews[language] || reviews.en);
    });
  },
};

export default ReviewService;
