import { Navigate, Route } from "react-router-dom";

import HealthProvidersDetails from "client/components/home/content/health-providers-details";
import {
  AboutUs,
  Activate,
  AppointmentDetails,
  Appointments,
  Cities,
  DoctorList,
  DoctorProfile,
  FAQ,
  ForgotPassword,
  HealthProviders,
  Home,
  LoginContainer,
  MedicalTourism,
  Password,
  PatientDetails,
  PrivacyPolicy,
  Profile,
  Register,
  ResetPassword,
  ServicesSurvey,
  ServicesSurveySummary,
  Specialities,
  TermsAndConditions,
  TreatmentSummary,
  Treatments,
  Turkey,
  WhatToExpect,
} from "../Pages";
import {
  MedicalSuccess,
  TravelSuccess,
  TreatmentSuccess,
} from "../components/success";
import ThankYou from "client/components/home/contact/ThankYou";

const ClientRoutes = (currentLang, setIsLoggedIn) => {
  const catchAll = (
    <Route path="*" element={<Navigate to={`/${currentLang}`} replace />} />
  );
  return [
    /* header routes */
    <Route path="" element={<Home />} />,
    <Route
      path="thank-you"
      element={<ThankYou contentId={"thank-you"} titleId={`Header.AboutUs`} />}
    />,
    <Route
      path="about-us"
      element={<AboutUs contentId={"about-us"} titleId={`Header.AboutUs`} />}
    />,
    <Route
      path="what-to-expect"
      element={
        <WhatToExpect
          contentId={"what-to-expect"}
          titleId={`Header.WhatToExpect`}
        />
      }
    />,
    <Route path="medical-tourism/*">
      <Route
        path="why-medical-tourism"
        element={
          <MedicalTourism
            contentId={"why-medical-tourism"}
            titleId={`Header.Why-Medical-Tourism`}
          />
        }
      />
      {catchAll}
    </Route>,
    <Route path="medical-tourism">
      <Route
        path="is-it-right-for-me"
        element={
          <MedicalTourism
            contentId={"is-it-right-for-me"}
            titleId={`Header.Is-it-right-for-me`}
          />
        }
      />
      <Route
        path="essentials-to-pack"
        element={
          <MedicalTourism
            contentId={"essentials-to-pack"}
            titleId={`Header.Essentials-to-pack`}
          />
        }
      />
      {catchAll}
    </Route>,
    <Route path="turkey">
      <Route
        path="about-turkey"
        element={
          <Turkey contentId={"about-turkey"} titleId={`Header.AboutTurkey`} />
        }
      />
      ,
      <Route
        path="why-turkey"
        element={
          <Turkey contentId={"why-turkey"} titleId={`Header.WhyTurkey`} />
        }
      />
      {catchAll}
    </Route>,
    <Route path="cities/*">
      <Route
        path="istanbul"
        element={
          <Cities contentId={"istanbul"} titleId={`Header.Cities-Istanbul`} />
        }
      />
      ,
      <Route
        path="antalya"
        element={
          <Cities contentId={"antalya"} titleId={`Header.Cities-Antalya`} />
        }
      />
      {catchAll}
    </Route>,
    <Route path="health-providers">
      <Route
        path="medical-park"
        element={
          <HealthProvidersDetails
            contentId={"medical-park"}
            titleId={`HealthProviders.MedicalPark`}
          />
        }
      />
      <Route
        path="dunyagoz"
        element={
          <HealthProvidersDetails
            contentId={"dunyagoz"}
            titleId={`HealthProviders.Dunyagoz`}
          />
        }
      />
      <Route
        path="estethica"
        element={
          <HealthProvidersDetails
            contentId={"estethica"}
            titleId={`HealthProviders.Estethica`}
          />
        }
      />
      <Route
        path="*"
        element={
          <HealthProviders
            contentId={"health-providers"}
            titleId={`Header.HealthProviders`}
          />
        }
      />
    </Route>,
    <Route
      path="faq"
      element={<FAQ contentId={"faq"} titleId={`Header.FAQ`} />}
    />,
    <Route
      path="terms-and-conditions"
      element={
        <TermsAndConditions
          contentId={"terms-and-conditions"}
          titleId={`Header.TermsAndConditions`}
        />
      }
    />,
    <Route
      path="privacy-policy"
      element={
        <PrivacyPolicy
          contentId={"privacy-policy"}
          titleId={`Header.PrivacyPolicy`}
        />
      }
    />,
    // {/* <Route path="specialities" element={<Specialities />} /> */},
    <Route path="doctor-list" element={<DoctorList />} />,
    <Route path="treatments" element={<Treatments />} />,
    <Route path="treatments-summary" element={<TreatmentSummary />} />,

    <Route path="doctor-profile" element={<DoctorProfile />} />,
    <Route path="patient-details" element={<PatientDetails />} />,
    <Route path="services-survey" element={<ServicesSurvey />} />,
    <Route
      path="services-survey-summary"
      element={<ServicesSurveySummary />}
    />,
    // {/* Account */},
    // {/* <Route path="account-dashboard" element={<Dashboard />} /> */},
    <Route
      path="account-profile"
      element={<Profile setIsLoggedIn={setIsLoggedIn} />}
    />,
    <Route path="account-appointments" element={<Appointments />} />,
    <Route
      path="account-appointment-details"
      element={<AppointmentDetails />}
    />,

    <Route path="account-specialities" element={<Specialities />} />,
    <Route path="account-change-password" element={<Password />} />,
    <Route
      path="login"
      element={<LoginContainer setIsLoggedIn={setIsLoggedIn} />}
    />,
    <Route path="register" element={<Register />} />,
    <Route path="travel-success" element={<TravelSuccess />} />,
    <Route path="medical-success" element={<MedicalSuccess />} />,
    <Route path="treatment-success" element={<TreatmentSuccess />} />,

    <Route path="activate" element={<Activate />} />,
    <Route path="forgot-password" element={<ForgotPassword />} />,
    <Route path="reset" element={<ResetPassword />} />,
  ];
};

export default ClientRoutes;
