// MenuPages.jsx
import React, { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AppContext from "store/app-store";
import styles from "./MenuPages.module.css";

const menuItems = [
  { path: "about-us", textId: "Header.AboutUs" },
  {
    path: "medical-tourism",
    textId: "Header.Medical-Tourism",
    subItems: [
      {
        path: "medical-tourism/why-medical-tourism",
        textId: "Header.Why-Medical-Tourism",
      },
      {
        path: "medical-tourism/is-it-right-for-me",
        textId: "Header.Is-it-right-for-me",
      },
      {
        path: "medical-tourism/essentials-to-pack",
        textId: "Header.Essentials-to-pack",
      },
    ],
  },
  {
    path: "turkey",
    textId: "Header.Turkey",
    subItems: [
      { path: "turkey/about-turkey", textId: "Header.AboutTurkey" },
      { path: "turkey/why-turkey", textId: "Header.WhyTurkey" },
    ],
  },
  {
    path: "cities",
    textId: "Header.Cities",
    subItems: [
      { path: "cities/istanbul", textId: "Header.Cities-Istanbul" },
      { path: "cities/antalya", textId: "Header.Cities-Antalya" },
    ],
  },
  { path: "health-providers", textId: "Header.HealthProviders" },
  { path: "faq", textId: "Header.FAQ" },
  { path: "treatments", textId: "Treatments" },
  { path: "what-to-expect", textId: "Header.WhatToExpect" },
];

const MenuItem = ({ 
  item, 
  currentLang, 
  translate, 
  isScrolled, 
  isLast,
  hoveredDropdown,
  setHoveredDropdown,
  isMobile 
}) => {
  const handleClick = (e) => {
    if (isMobile && item.subItems) {
      e.preventDefault();
      e.stopPropagation();
      // Close other dropdowns when opening a new one
      setHoveredDropdown(hoveredDropdown === item.textId ? null : item.textId);
    }
  };

  return item.subItems ? (
    <NavDropdown
      title={translate(item.textId)}
      id={`nav-dropdown-${item.textId}`}
      className={`${isScrolled ? styles.navItemScrolled : styles.navItem}`}
      show={hoveredDropdown === item.textId}
      onMouseEnter={() => !isMobile && setHoveredDropdown(item.textId)}
      onMouseLeave={() => !isMobile && setHoveredDropdown(null)}
      onClick={handleClick}
    >
      {item.subItems.map((subItem, idx) => (
        <NavDropdown.Item
          key={idx}
          href={`/${currentLang}/${subItem.path}`}
          onClick={(e) => {
            e.stopPropagation();
            if (isMobile) {
              setHoveredDropdown(null);
            }
          }}
        >
          {translate(subItem.textId)}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  ) : (
    <Nav.Link 
      href={`/${currentLang}/${item.path}`} 
      className={isScrolled ? styles.navItemScrolled : styles.navItem}
      onClick={() => isMobile && setHoveredDropdown(null)}
    >
      {translate(item.textId)}
    </Nav.Link>
  );
};

const MenuPages = ({ isScrolled, toggleLanguage }) => {
  const { t } = useTranslation();
  const { getLang } = useContext(AppContext);
  const currentLang = getLang();
  const [hoveredDropdown, setHoveredDropdown] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        setHoveredDropdown(null);
      }
    };

    if (isMobile) {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }
  }, [isMobile]);

  return (
    <Navbar expand="lg" className={isScrolled ? styles.scrolledLink : styles.defaultLink}>
      <Container className={styles.menuContainer}>
        <Nav className={`me-auto main-nav ${styles.navWrapper}`}>
          {menuItems.map((item, idx) => (
            <>
              <MenuItem
                key={idx}
                item={item}
                currentLang={currentLang}
                translate={t}
                isScrolled={isScrolled}
                isLast={idx === menuItems.length - 1}
                hoveredDropdown={hoveredDropdown}
                setHoveredDropdown={setHoveredDropdown}
                isMobile={isMobile}
              />
              {!isMobile && <span className={styles.separator}>|</span>}
            </>
          ))}
          
          
          <Nav.Link 
            onClick={toggleLanguage} 
            className={`${styles.langLink} ${isScrolled ? styles.navItemScrolled : styles.navItem} ${isScrolled ? styles.scrolledLink : styles.defaultLink}`}
            dir={currentLang === "ar" ? "ltr" : "rtl"}
          >
            {currentLang === "ar" ? "English" : "العربية"}
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default MenuPages;