import AuthService from "client/services/auth.service";
import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import AppContext from "store/app-store";
import styles from "./Header.module.css";
import YmpLogo from "./YmpLogo";
import { MenuButton, MenuCloseButton, MenuLangPicker, MenuPages } from "./menu";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Header = (props) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [hide, setHide] = useState(true);
  const [t, i18next] = useTranslation();

  const [isFading, setIsFading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Timer for fading effect
    const fadeTimer = setTimeout(() => setIsFading(false), 10000);

    // Scroll listener to toggle header background
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(fadeTimer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logout = () => {
    AuthService.logout();
    props.setIsLoggedIn(false);
    navigate("/");
  };

  const onHandleCloseMenu = () => {
    document.getElementsByTagName("html")[0].classList.remove("menu-opened");
  };

  const Backdrop = (props) => {
    return (
      <div
        className={!hide ? styles.backdrop : ""}
        onClick={() => {
          setHide(true);
          onHandleCloseMenu();
        }}
      />
    );
  };

  const changeLanguage = (language) => {
    appContext.changeLang(language);
    const path = window.location.pathname
      .substring(1, window.location.pathname.length)
      .split("/");
    const newPath = [language, ...path.slice(1)].join("/");
    window.location.pathname = `/${newPath}`;
  };

  const toggleLanguage = () => {
    // Close the menu first
    onHandleCloseMenu();

    // Wait for the menu to fully close before switching language
    setTimeout(() => {
      const newLanguage = appContext.getLang() === "ar" ? "en" : "ar";
      appContext.changeLang(newLanguage);

      // Update the URL path based on the new language
      const path = window.location.pathname.substring(1).split("/");
      const newPath = [newLanguage, ...path.slice(1)].join("/");
      window.location.pathname = `/${newPath}`;
    }, 300); // Adjust the timeout duration to match the CSS transition duration (e.g., 300ms)
  };

  const toggleLanguage_OLD = () => {
    const newLanguage = appContext.getLang() === "ar" ? "en" : "ar";
    appContext.changeLang(newLanguage);
    const path = window.location.pathname.substring(1).split("/");
    const newPath = [newLanguage, ...path.slice(1)].join("/");
    window.location.pathname = `/${newPath}`;
  };

  const isSpecialPath = () => {
    const values = ["treatment-success", "treatments-summary"];
    return values.some(value => window.location.pathname.includes(value));
  };

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}

      <header
        className={`${styles.header} ${
          isScrolled ? styles.solid : styles.transparent
        }`}
        hidden={!!props.isLoggedIn || isSpecialPath()}
      >
        <nav
          className={`navbar navbar-expand-lg header-nav ${
            isScrolled ? "solid" : "nav-transparent"
          }`}
        >
          <div className="navbar-header">
            <MenuButton
              setHide={setHide}
              isFlipped={appContext.getLang() === "ar"}
              isScrolled={isScrolled}
            />
            <YmpLogo
              linkClassName="navbar-brand logo"
              imageClassName="img-fluid logo-header"
              isScrolled={isScrolled}
            />
          </div>

          <div className="main-menu-wrapper">
            <div className="menu-header">
              {/* <YmpLogo onClick={onHandleCloseMenu} /> */}
              <MenuCloseButton onClick={onHandleCloseMenu} />
            </div>
            <MenuPages
              onClick={onHandleCloseMenu}
              isLoggedIn={props.isLoggedIn}
              onLogout={logout}
              isScrolled={isScrolled}
              toggleLanguage={toggleLanguage}
            />
            {/* <MenuLangPicker
              onClick={onHandleCloseMenu}
              changeLang={changeLanguage}
              isMobile={true}
            /> */}
            {/* <span className={styles.languageSwitcher}>
              <a
                onClick={toggleLanguage}
                style={{ cursor: "pointer", color: isScrolled ? "#555" : "#fff" }}
              >
                {appContext.getLang() === "ar" ? "English" : "العربية"}
              </a>
            </span> */}
          </div>
          <span className="mx-auto" hidden>
            <a
              href={`/${appContext.getLang()}/treatments`}
              class="btn btn-outline-primary mt-lg-0 mt-2"
              role="button"
              aria-pressed="true"
            >
              {t("StartNow") + "  "}
              {/* <i className={`fas fa-arrow-${appContext.getLang() === "ar" ? "left" : "right"}`}></i> */}
              <FontAwesomeIcon
                icon={
                  appContext.getLang() === "ar" ? faChevronLeft : faChevronRight
                }
                fade={isFading ? "fade" : ""}
              />
            </a>
          </span>

          {/* <MenuLangPicker
            onClick={onHandleCloseMenu}
            changeLang={changeLanguage}
          /> */}
          {/* <MenuLoginSignup isLoggedIn={props.isLoggedIn} currentUser={AuthService.getCurrentUser()} logout={logout} /> */}
        </nav>
      </header>
    </>
  );
};

export default Header;
