const onHandleMobileMenu = (setHide) => {
    setHide(false);
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
};

const MenuButton = ({ setHide, isFlipped, isScrolled }) => {
    return (
        <a href="#" id="mobile_btn" onClick={() => onHandleMobileMenu(setHide)}>
            <span className={`bar-icon ${isFlipped ? "mirroredBarIcon" : ""}`}>
                <span id={isScrolled ? "BarIconScrolled" : ""}></span>
                <span id={isScrolled ? "BarIconScrolled" : ""}></span>
                <span id={isScrolled ? "BarIconScrolled" : ""}></span>
            </span>
        </a>
    )
}

export default MenuButton;